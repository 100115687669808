import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import $ from "jquery"

import t from "locale"

const NotFoundPage = () => {
  const lang = "en"
  useEffect(() => {
    $("footer").addClass("error-page")
  })
  return (
    <Layout lang={lang}>
      <SEO title="404: Not found" />
      <section className="error-section">
        <img src={require("../assets/images/ico-fish.png")} alt="" />
        <h1 className="error-section__title">
          <span>OOOOOPS!</span>
          <br />
          The page does not exist{" "}
        </h1>
        <p className="error-section__desc">
          Would you like to go to the frontpage?
        </p>
        <Link
          to={t("/", lang)}
          className="btn btn--white error-section__button"
        >
          Go to main page
        </Link>
      </section>
    </Layout>
  )
}

export default NotFoundPage
